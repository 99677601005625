import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
// import SearchModal from '../../components/modal/SearchModal';
import ModalMenuPrimary from '../../blocks/menu/ModalMenuPrimary';
import ModalMenuSecondary from '../../blocks/menu/ModalMenuSecondary';
import ModalMenuLanguages from '../../blocks/menu/ModalMenuLanguages';
import Actions from '../../blocks/actions/Actions';
import { Link } from 'react-router-dom';

class SearchModal extends Component {

    constructor(context) {
        super(context);

        this.state = {
            showModal: false
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState({ showModal: true });
    }

    close() {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <Fragment>
                <div className="menu-toggle">
                    <Link to="/HomeEN">
                        <button type="button" className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">
                            En/中
                        </button>
                    </Link>
                </div>

            </Fragment>
        );
    }
}

export default SearchModal;

