import React from 'react';

const AboutsUsEn = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>Laydeez Skin Laser Clinic is located on the main street of Burwood, one of the most bustling Asian districts in the heart of Sydney, New South Wales.</h2>
                        </div>
                    </div>
                </div>

                {/* <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>We are a multidimensional medical aesthetics center integrating skin management, anti-aging treatments, laser spot removal, postnatal repair, and Japanese facial slimming techniques. We boast the most advanced equipment and a team with over ten years of rich experience, providing personalized and customized professional medical aesthetics and postnatal repair services. Upon every client's arrival, we offer our sincerest enthusiasm and professional perspective to provide expert advice for your skin concerns and various issues.</p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="description">
                            <p>TAKES TREATMENT FIVE  OFF YOUR FACE</p>
                            <p>The FUTURE IS SPERB</p>
                            <p>100% NON-INVASIVE</p>
                            <p>QUICK Treatment</p>
                            <p>DOWNTIME</p>
                            <p>COMFORTABLE TREATMENT</p>
                        </div>
                    </div>
                </div> */}

                <div class="row gutter-width-sm justify-content-end">
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p>We are a multidimensional medical aesthetics center integrating skin management, anti-aging treatments, laser spot removal, postnatal repair, and Japanese facial slimming techniques. We boast the most advanced equipment and a team with over ten years of rich experience, providing personalized and customized professional medical aesthetics and postnatal repair services. Upon every client's arrival, we offer our sincerest enthusiasm and professional perspective to provide expert advice for your skin concerns and various issues.</p>
                        </div>
                    </div>
                    {/* <div class="col-xl-2 col-lg-2 d-none d-lg-block"></div> */}
                    {/* <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p>TAKES TREATMENT FIVE OFF YOUR FACE</p>
                            <p>The FUTURE IS SPERB</p>
                            <p>100% NON-INVASIVE</p>
                            <p>QUICK Treatment</p>
                            <p>DOWNTIME</p>
                            <p>COMFORTABLE TREATMENT</p>
                        </div> */}
                    {/* </div> */}
                </div>

            </div>
        </section >
    );
};

export default AboutsUsEn;
