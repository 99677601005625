import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import Footer from '../blocks/footer/Footer';

import PageTitleServices from '../blocks/page-title/PageTitleServicesEn';
import ServicesContent from "../blocks/services/ServicesContent";
import ServicesContentEn from '../blocks/services/ServicesContentEn';
import PageTitleServicesInsideEn from '../blocks/page-title/PageTitleServicesInsideEn';
import HeaderEn from '../blocks/header/HearderEn';
import PageTitleServicesEn from '../blocks/page-title/PageTitleServicesEn';

const ServicesEn = () => {
    document.body.classList.add( 'archive' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Services | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark" />

            <main id="main" className="site-main">
            <PageTitleServicesEn />

                <div id="page-content" className="spacer p-top-xl">
                    <ServicesContentEn />
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default ServicesEn;
