import React from 'react';

const AboutServicesEn = () => {
    return (
        <div id="services" className="block spacer p-top-xl">
            <div className="text-center">
                <a href={ process.env.PUBLIC_URL + "/servicesEn" } className="btn btn-outline-secondary">More about Our service Details</a>
            </div>
        </div>
    );
};

export default AboutServicesEn;