import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactForm from '../components/form/ContactForm';

import GoogleMaps from '../blocks/google-maps/GoogleMaps';
import ContactsInside from '../blocks/contacts/Contacts';

const Contacts = () => {
    document.body.classList.add( 'page' );

    const itemStyle = {
        marginLeft: '5rem',
    };

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>联系我们 | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleContacts />
                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <GoogleMaps />

                                <div className="spacer p-top-xl">
                                    <div className="title" style={itemStyle}>
                                        <h2>我们的联系方式</h2>
                                    </div>

                                    <div className="row gutter-width-sm with-pb-xl spacer p-top-lg">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <ContactsInside />
                                        </div>

                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                        <div className="contact-form-shortcode" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {/* 假设这是其他内容 */}
                                            {/* <ContactForm /> */}
                                            
                                            {/* 图片 */}
                                            <img src="assets/img/placeholder/Laydeez-wechat qrcode.png  " alt="描述性文本" style={{ width: '30%', height: '30%', marginLeft: '50%' }} />
                                        </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
